import { reactive, toRefs  } from "vue";
import Timer from 'easytimer.js'
import moment from "moment";
import localforage from 'localforage';


const lottieStorage = localforage.createInstance({
  name: 'Lottie-Time-tracking',
  driver: localforage.LOCALSTORAGE,
});

const state = reactive({
  issue: null,
  seconds: 0,
  timer: null,
  isRunning: false,
  pause: false,
  selected: false,
  completed: false,
  counter: '00:00:00',
});



export default function useTimeTracking() {

  const initData = async () => {

    let tt_counter_seconds = await lottieStorage.getItem('tt_counter_seconds')
    let tt_counter_content = await lottieStorage.getItem('tt_counter_content')
    let tt_pause = await lottieStorage.getItem('tt_pause')
    let tt_completed = await lottieStorage.getItem('tt_completed')
    let tt_is_running = await lottieStorage.getItem('tt_is_running')
    let tt_issue = await lottieStorage.getItem('tt_issue')


    state.seconds = tt_counter_seconds ? tt_counter_seconds : 0
    state.counter = tt_counter_content ? tt_counter_content : '00:00:00'
    state.pause = tt_pause === 1 ? true : false
    state.isRunning = tt_is_running === 1 ? true : false
    state.completed = tt_completed === 1 ? true : false

    if ( tt_issue ) {

      console.log('initdata tt_issue: ' + tt_issue)
      state.issue = Object.assign({}, JSON.parse(tt_issue))
    }

    state.timer = new Timer()

    if (state.timer) {

      if (state.counter !== '00:00:00' && state.seconds > 0) {
      //   state.isRunning = true
        if (!state.pause) {

          state.timer.start({
            startValues: {
              seconds: state.seconds
            }
          });

          lottieStorage.setItem('tt_is_running', 1)
        }
      }
    }



  }

  const initIssueData = (issue, uuid) => {
    lottieStorage.setItem( 'tt_issue',
      JSON.stringify({ iid: issue.iid, piid: issue.project_id, title: issue.title, uuid: uuid })
    ).then((value) => {

      console.log('JSON.parse(value)')
      console.log(JSON.parse(value))

      state.issue = Object.assign({}, JSON.parse(value))

      lottieStorage.setItem( 'tt_selected', 1)
      state.selected = true
      console.log('value')
      console.log(value)
      return value;
    }).catch((err) => {
        console.log('oops! ', err);
    });
  }

  const getDurationInSeconds = (val) => {
    return moment.duration(val).asSeconds()
  }

  // actions

  const toggleTimer = async () => {
    state.pause = !state.pause
    lottieStorage.setItem('tt_pause', state.pause ? 1 : 0)
  }

  const startTimer = () => {
    if ( !state.timer )  state.timer = new Timer()

    state.timer.start({
      startValues: {
        seconds: state.seconds
      }
    });

    state.timer.addEventListener('secondsUpdated', (e) => {
      state.counter = state.timer.getTimeValues().toString();
      state.seconds = state.timer.getTotalTimeValues().seconds

      if (!state.pause) {
        lottieStorage.setItem('tt_counter_content', state.counter)
        lottieStorage.setItem('tt_counter_seconds', state.seconds)
      }

    });

    state.isRunning = true
    lottieStorage.setItem('tt_is_running', 1)
  }

  const pauseTimer = () => {
      state.timer.pause();
      toggleTimer()
  }

  const resumeTimer = () => {
    toggleTimer()
    state.timer.start({
      startValues: {
        seconds: state.seconds
      }
    });
  }



  const resetTimer = () => {
    state.timer.reset();
  }

  const reset = () => {
    state.isRunning = false
    state.pause = false
    state.completed = false
    state.counter = '00:00:00'
    state.seconds = 0
    state.timer = null
    state.selected = false
    state.issue = Object.assign({}, {
      iid: 0,
      title: '',
      uuid: 0,
      piid: 0,
    })
  }


  const onCompleteHandle = () => {
    state.timer.stop();
    state.isRunning = false
    state.pause = false
    state.completed = true
    lottieStorage.setItem('tt_is_running', 0)
    lottieStorage.setItem('tt_pause', 0)
    lottieStorage.setItem('tt_completed', 1)
    lottieStorage.setItem('tt_selected', 0)
  }


  const onSubmit = () => {
    console.log(getDurationInSeconds(state.counter))
    reset()
    lottieStorage.clear()
  }



  return {
    ...toRefs(state), // convert to refs when returning
    initData,
    toggleTimer,
    startTimer,
    pauseTimer,
    resumeTimer,
    resetTimer,
    onCompleteHandle,
    onSubmit,
    initIssueData
  }

}

